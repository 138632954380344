import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LDKeyAgents } from "../../components/layout/Sidebar";
import { useAppState } from "../../state/AppStateProvider";
import { PageContentContainer } from "../styled/styled";
import { Box } from "@mui/material";

import {
  Flex,
  Text,
  Button,
  Select,
  TextField,
  Callout,
  Spinner,
  Card,
  Tabs,
  Table,
  IconButton,
} from "@radix-ui/themes";
import {
  createLiveAgent,
  deployLiveAgent,
  fetchArtifactFilesWithUrl,
  getArtifactLibraryItems,
  getSmartWallets,
} from "../../api/hasura";
import useSWR from "swr";
import { ChainBadge } from "../wallets/ChainBadge";
import { delegateRole, createZodiacPermissioTxnWithPermission } from "../wallets/safeFunctions";
import Safe from "@safe-global/protocol-kit";
import { TransactionResponse } from "@ethersproject/providers";
import { ALMANAK_AGENT_ROLE_KEY, ALMANAK_AGENT_ROLE_NAME } from "../wallets/WalletCreationFlow";
import {
  ArrowLeftIcon,
  ExclamationTriangleIcon,
  InfoCircledIcon,
  OpenInNewWindowIcon,
} from "@radix-ui/react-icons";
import { ArrowLeft } from "@mui/icons-material";
import { formatMonetaryValue } from "../simulation/create-simulation/PoolDetails";
import { formatPubKey } from "../../utils/commonUtils";
import { openTransaction } from "../wallets/WalletDetails";
import { python } from "@codemirror/lang-python";
import CodeMirror from "@uiw/react-codemirror";

import { json } from "@codemirror/lang-json";
import ErrorBoundary from "../../components/error-handling/ErrorBoundary";

export const AgentDeployment = () => {
  const navigate = useNavigate();
  const { theme, featureFlags, provider, setSnackBar } = useAppState();
  const isAgentsEnabled = featureFlags?.[LDKeyAgents];

  console.log("provider", provider);

  const [agentName, setAgentName] = useState("");
  const [selectedStrategy, setSelectedStrategy] = useState("");
  const [selectedWallet, setSelectedWallet] = useState("");
  const [isDeploying, setIsDeploying] = useState(false);

  const [selectedTab, setSelectedTab] = useState<"permissions" | "config">(
    "permissions"
  );

  const { data: artifactList, isLoading: isLoadingArtifacts } = useSWR(
    "strategy-templates",
    getArtifactLibraryItems
  );

  const selectedArtifactVersion = useMemo(() => {
    const artifact = artifactList?.find(
      (artifact: any) => artifact.id === selectedStrategy
    );
    console.log("XASA artifact", artifact);
    const latestVersion = artifact?.latest_version_artifact;
    console.log("XASA latestVersion", latestVersion);

    return {
      ...latestVersion,
      version: latestVersion?.name,
      artifactName: artifact?.name,
    };
  }, [selectedStrategy, artifactList]);

  console.log("XASA - selectedArtifactVersion", selectedArtifactVersion);

  const { data: artifactFilesWithUrl } = useSWR(
    selectedArtifactVersion ? `${selectedArtifactVersion?.id}-files` : null,
    () =>
      fetchArtifactFilesWithUrl(
        selectedArtifactVersion?.artifactName,
        selectedArtifactVersion?.version
      )
  );

  console.log("artifactFilesWithUrl", artifactFilesWithUrl);
  const configFileUrl = useMemo(() => {
    const relativeConfigFilePath =
      "/" + selectedArtifactVersion?.metadata?.config_file_path;
    const fileUrl = artifactFilesWithUrl?.find(
      (file: any) => file.relative_path === relativeConfigFilePath
    )?.presigned_url;

    return fileUrl;
  }, [artifactFilesWithUrl, selectedArtifactVersion]);

  const { data: configFileContents } = useSWR(configFileUrl, async () => {
    const res = await fetch(configFileUrl);
    return res.json();
  });

  const [agentConfiguration, setAgentConfiguration] =
    useState<any>(configFileContents);

  useEffect(() => {
    console.log("configFileContents", configFileContents);
    setAgentConfiguration(configFileContents);
  }, [configFileContents]);

  console.log("configFileContents", configFileContents);

  console.log("configFile", configFileUrl);
  console.log("artifactFilesWithUrl", artifactFilesWithUrl);
  // const selectedArtifactVersionFiles = useMemo(async () => {
  //
  //     console.log("selectedArtifactVersion", selectedArtifactVersion);
  //     const artifactName = selectedArtifactVersion?.name;
  //     const version = selectedArtifactVersion?.version;
  //
  //     const filesWithUrls = await fetchArtifactFilesWithUrl(artifactName, version);
  //
  //     console.log("selectedArtifactVersionFiles filesWithUrls", filesWithUrls);
  //     return filesWithUrls;
  // }, [selectedArtifactVersion]);

  // console.log("selectedArtifactVersionFiles", selectedArtifactVersionFiles);
  const {
    data: walletList,
    isLoading: isLoadingWallets,
    mutate: mutateWallets,
    isValidating: isWalletsValidating,
  } = useSWR(`READY-user-wallets`, () => getSmartWallets("ALL", "READY"));

  const selectedWalletDetails = useMemo(() => {
    return walletList?.find((wallet: any) => wallet.id === selectedWallet);
  }, [selectedWallet, walletList]);

  const isAccessControlVisible = useMemo(() => {
    console.log("isAccessControlVisible selectedWallet", selectedWallet);
    console.log("isAccessControlVisible selectedStrategy", selectedStrategy);

    const ret = selectedWallet !== "" && selectedStrategy !== "";
    console.log("ret", ret);
    return ret;
  }, [selectedWallet, selectedStrategy]);

  const isAgentReadyToDeploy = useMemo(() => {
    return selectedWallet !== "" && selectedStrategy !== "" && agentName !== "";
  }, [selectedWallet, selectedStrategy, agentName]);

  const hasNoWallets = useMemo(() => {
    return !isLoadingWallets && (!walletList || walletList.length === 0);
  }, [isLoadingWallets, walletList]);

  if (!isAgentsEnabled) {
    return (
      <PageContentContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <h2>Agents feature is coming soon!</h2>
        </Box>
      </PageContentContainer>
    );
  }

  const handleDeploy = async () => {
    const configMockMarcia = {
      agent_config: {
        USER_ENV_1: "a8fuds89iha9uhbdvdshas9r8do",
        USER_ENV_2: "madf8a8798dasyfad",
        USER_ENV_3: "u82q0hfnvpdoasjdofijdas",
        ALMANAK_STRATEGY_CONFIG: {
          shared_configs: {
            default_config: {
              mode: "EXECUTION",
              chain: "ARBITRUM",
              network: "ANVIL",
              cosigner: { name: "NONE" },
              protocol: "UNISWAP_V3",
              slippage: {
                slippage_open: 0.02,
                slippage_swap: 0.005,
                slippage_close: 0.02,
                slippage_spot_rate: 0.02,
              },
              simulation: false,
              granularity: "1h",
              price_model: {
                method: "hma",
                params: { price_window: 56 },
                data_format: "OHLCV",
                data_source: "COINGECKO_DEX",
              },
              strategy_name: "Dynamic_LP_Volatility",
              pause_strategy: false,
              wallet_address: "0xbD281D2121CA820213726d7956f31059688aeE19",
              volatility_model: {
                method: "std",
                params: {
                  volatility_factor: 2,
                  volatility_window: 56,
                  volatility_window_type: "rolling",
                },
                data_format: "OHLCV",
                data_source: "COINGECKO_DEX",
              },
              initiate_teardown: true,
              max_sadflow_retries: 1,
              strategy_update_params: {
                rebalance_type: "DEFAULT",
                lp_bounds_ratio: 0.5,
                price_drift_type: "MODEL_POSITION",
                rebalance_condition: "VSOPD",
                rebalance_frequency: 24,
                lp_bounds_calculation: "VOLATILITY",
                price_drift_threshold: 0.05,
                recalculate_frequency: 1,
                rebalance_grace_period: 0,
                volatility_spread_type: "RAW_POSITION",
                volatility_spread_threshold: 0.1,
                rebalance_out_of_position_period: 0,
              },
              max_not_included_retries: 10,
            },
          },
          strategy_configs: {
            QA_PENDLE_WETH_: {
              parameters: {
                pool_name: "PENDLE/WETH 3000",
                pool_address: "0xdbaeB7f0DFe3a0AAFD798CCECB5b22E708f7852c",
                initialization: {
                  initial_token0_pool:
                    "0xdbaeB7f0DFe3a0AAFD798CCECB5b22E708f7852c",
                  initial_token1_pool: "N/A",
                  initial_funding_token:
                    "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
                  initial_position_value_USD: 500,
                  initial_funding_token0_amount: 0,
                  initial_funding_token1_amount: 0,
                },
                shared_parameters: "default_config",
              },
              strategy_name: "Dynamic_LP_Volatility",
            },
          },
        },
        ALMANAK_PLATFORM_WALLETS: [
          {
            CHAIN_ID: 42161,
            CHAIN_TYPE: "EVM",
            EOA_ADDRESS: "0xc2f59aa328896252f16e2e381987b6628b902b33",
            SAFE_ACCOUNT_NAME: "SAFE",
            SAFE_ACCOUNT_ADDRESS: "0x5e85577e7fd14b5e489d2f34a6bcae68f7396889",
          },
        ],
        ALMANAK_SIGNER_SERVICE_JWT: "",
      },
      strategy_gcs_location:
        "gs://almanak_library_us/dynamic_lp_volatility_integration_test/0.0.1",
      enterprise_image_version: "",
    };

    console.log("handleDeploy deploy agent! agent name:", agentName);
    console.log("handleDeploy Deploying agent:", {
      agentName,
      strategy: selectedStrategy,
      selectedWallet,
    });

    console.log("handleDeploy selectedStrategy", selectedStrategy);
    console.log(
      "handleDeploy selectedArtifactVersion",
      selectedArtifactVersion
    );
    // console.log("handleDeploy configFileContents", configFileContents);

    console.log("handleDeploy agentConfiguration", agentConfiguration);
    // const config = configMockMarcia; //JSON.parse(configFileContents ?? "{}");

    const config = {
      agent_config: {
        ALMANAK_STRATEGY_CONFIG: agentConfiguration,
        ALMANAK_PLATFORM_WALLETS: [
          {
            CHAIN_ID: selectedWalletDetails?.chain_id,
            CHAIN_TYPE: "EVM",
            EOA_ADDRESS:
              selectedWalletDetails?.external_own_accounts?.[0].address,
            SAFE_ACCOUNT_NAME: selectedWalletDetails.name,
            SAFE_ACCOUNT_ADDRESS: selectedWalletDetails?.address,
          },
        ],
      },
      strategy_gcs_location: selectedArtifactVersion?.metadata?.uri,
    };

    console.log("config", config);
    const strategyVersion = selectedArtifactVersion?.version;
    const agent = await createLiveAgent(
      agentName,
      selectedArtifactVersion?.id,
      config,
      strategyVersion
    );

    const deployedAgent = await deployLiveAgent(agent.id);

    console.log("handleDeploy deployedAgent", deployedAgent);
    setSnackBar({
      open: true,
      message: "Agent Deploying",
      severity: "success",
    });
    console.log("agent", agent);
    // After successful deployment, navigate back to the overview
    navigate(`/agents/${agent?.id}`);
  };

  // const enableAccessForAlmanakAgent = async () => {
  //
  //
  //     const protocolKit = await Safe.init({provider: provider, safeAddress: walletDetails.address});
  //
  //     const rolesModTrx = await getDeployRolesModuleTrx();
  //     const expectedZodiacModuleAddress = rolesModTrx[1].to;
  //
  //     const applyRoleTrx = await getApplyRoleTRX(expectedZodiacModuleAddress, ALMANAK_AGENT_ROLE_NAME)
  //
  //     const transactions = [...rolesModTrx, ...applyRoleTrx]
  //
  //     const safeTransaction = await protocolKit.createTransaction({transactions: transactions})
  //     const txResponse = await protocolKit.executeTransaction(safeTransaction)
  //
  //     if (txResponse.transactionResponse) {
  //         const transactionResponse = txResponse.transactionResponse as TransactionResponse;
  //         await transactionResponse.wait(); // Call wait() on transactionResponse
  //         console.log('Zodiac Roles modifier module is deployed and enabled');
  //         console.log('Contract address: ', expectedZodiacModuleAddress);
  //     } else {
  //         console.error('transactionResponse is undefined.');
  //     }
  // };

  const handleGrantPermissions = async () => {
    if (!provider) {
      console.error("handleGrantPermissions provider not initialized!");
      return;
    }

    console.log(
      "handleGrantPermissions Granting permissions for wallet:",
      selectedWallet
    );
    console.log(
      "handleGrantPermissions Granting permissions for selectedArtifactVersion:",
      selectedArtifactVersion
    );

    const permissionsRequiredByStrategy =
      selectedArtifactVersion?.metadata?.safe_wallets_permission;

    console.log(
      "handleGrantPermissions permissionsRequiredByStrategy",
      permissionsRequiredByStrategy
    );

    console.log(
      "handleGrantPermissions selectedWalletDetails",
      selectedWalletDetails
    );

    const safeAddress = selectedWalletDetails?.address;
    const protocolKit = await Safe.init({
      provider: provider,
      safeAddress: safeAddress,
    });

    const modules = await protocolKit.getModules();

    console.log("handleGrantPermissions modules", modules);
    const expectedZodiacModuleAddress = modules[0];


    const transactions = await createZodiacPermissioTxnWithPermission(
      expectedZodiacModuleAddress,
      ALMANAK_AGENT_ROLE_KEY,
      selectedWalletDetails?.chain_id,
      "replace",
      permissionsRequiredByStrategy
    );
    const safeTransaction = await protocolKit.createTransaction({transactions: transactions});
    const txResponse = await protocolKit.executeTransaction(safeTransaction);

    console.log("handleGrantPermissions txResponse", txResponse);

    // const delegatedRole = await delegateRole(null, "almanakEOAAddress", selectedWallet, "chainId");
  };

  const onConfigChange = (value: string) => {
    console.log("onConfigChange", value);
    setAgentConfiguration(value);
  };
  const submitAgent = async () => {
    console.log("submitAgent selectedWallet", selectedWallet);
    console.log("submitAgent selectedStrategy", selectedStrategy);
    console.log("submitAgent configFileContents", configFileContents);

    setIsDeploying(true);
    try {
      await handleGrantPermissions(); // this deploy and grant permissions to the agent role
      await handleDeploy();
    } catch (e: any) {
      console.error("submitAgent error", e);
      setSnackBar({
        open: true,
        message: e.message?.substr(0, 20),
        severity: "error",
      });
    } finally {
      setIsDeploying(false);
    }
  };

  return (
    <PageContentContainer>
      <Flex direction="column" gap="6" align={"center"}>
        <Flex justify="start" gap={"2"} align="center" width={"100%"}>
          <Button variant={"outline"} onClick={() => navigate("/agents")}>
            <ArrowLeftIcon />
            Back
          </Button>
          <Text size="6" weight="bold">
            Deploy New Agent
          </Text>
        </Flex>
        <Flex
          direction="column"
          gap="4"
          style={{ width: "100%", maxWidth: "800px" }}
        >
          {hasNoWallets && (
            <Callout.Root color="red">
              <Callout.Icon>
                <ExclamationTriangleIcon />
              </Callout.Icon>
              <Callout.Text>
                No wallets ready to deploy! Please create and setup wallet
                before deploying an agent.
              </Callout.Text>
            </Callout.Root>
          )}

          <TextField.Root
            placeholder="Enter Agent name"
            value={agentName}
            onChange={(e) => setAgentName(e.target.value)}
          >
            <TextField.Slot>
              {/*<Text size="2" weight="bold">Agent Name</Text>*/}
            </TextField.Slot>
          </TextField.Root>
          <Select.Root
            value={selectedStrategy}
            onValueChange={setSelectedStrategy}
          >
            <Select.Trigger placeholder="Select a strategy" />
            <Select.Content>
              {isLoadingArtifacts ? (
                <Select.Item value="loading">Loading...</Select.Item>
              ) : (
                artifactList?.map((artifact: any) => (
                  <Select.Item key={artifact.id} value={artifact.id}>
                    {artifact.name}
                  </Select.Item>
                ))
              )}
            </Select.Content>
          </Select.Root>
          <Select.Root value={selectedWallet} onValueChange={setSelectedWallet}>
            <Select.Trigger placeholder="Select a wallet" />
            <Select.Content>
              {isLoadingWallets ? (
                <Select.Item value="loading">Loading...</Select.Item>
              ) : (
                walletList?.map((wallet: any) => (
                  <Select.Item key={wallet.id} value={wallet.id}>
                    <Flex width="100%" align="center" gap="2">
                      {wallet.name}
                      <ChainBadge chainId={wallet.chain_id} />
                    </Flex>
                  </Select.Item>
                ))
              )}
            </Select.Content>
          </Select.Root>

          {isAccessControlVisible && (
            <Tabs.Root
              defaultValue="permissions"
              value={selectedTab}
              onValueChange={(value: string) =>
                setSelectedTab(value as "permissions" | "config")
              }
            >
              <Tabs.List>
                <Tabs.Trigger value="permissions">
                  Strategy Permissions
                </Tabs.Trigger>
                <Tabs.Trigger value="config">
                  Strategy Configuration
                </Tabs.Trigger>
              </Tabs.List>

              <Box style={{ marginTop: "16px" }}>
                <Tabs.Content value="permissions">
                  {selectedArtifactVersion?.metadata
                    ?.safe_wallets_permission && (
                    <ErrorBoundary>
                      <CodeMirror
                        readOnly={true}
                        value={JSON.stringify(
                          selectedArtifactVersion?.metadata
                            ?.safe_wallets_permission,
                          null,
                          2
                        )}
                        theme={theme}
                        extensions={[json()]}
                      />
                    </ErrorBoundary>
                  )}
                </Tabs.Content>

                <Tabs.Content value="config">
                  {configFileContents && (
                    <CodeMirror
                      readOnly={false}
                      onChange={onConfigChange}
                      value={JSON.stringify(configFileContents, null, 2)}
                      theme={theme}
                      extensions={[json()]}
                    />
                  )}
                </Tabs.Content>
              </Box>
            </Tabs.Root>
          )}

          <Button
            disabled={!isAgentReadyToDeploy || isDeploying}
            onClick={submitAgent}
          >
            {isAgentReadyToDeploy && "Grant Permissons and "}Deploy Agent
            {isDeploying && <Spinner />}
          </Button>

          {/*<Button*/}
          {/*    onClick={handleDeploy}*/}
          {/*    disabled={hasNoWallets || !isAgentReadyToDeploy}*/}
          {/*>*/}
          {/*    Deploy Agent*/}
          {/*</Button>*/}
        </Flex>
      </Flex>
    </PageContentContainer>
  );
};
